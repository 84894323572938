// useTheme.js
import { computed } from 'vue';
import {useAuth0} from "@auth0/auth0-vue";

export function checkPermissionByEmail() {
    const auth = useAuth0();

    const isUserExclusive = computed(() => {
        const emailParts = auth.user.value.email.split('@');
        const domain = emailParts[1];
        console.log(domain)
        switch (domain) {
            case 'moore-drv.nl':
            case 'crossminds.nl':
            case 'zantboer.nl':
            case 'redefine-it.eu':
            case 'drv.nl':
            case 'ruitenburg.nl':
            case 'imaginecreativeagency.nl':
                return true;
            default:
                return false;
        }
    });

    return {
        isUserExclusive
    };
}
