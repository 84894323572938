// useTheme.js
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useTheme() {
    const route = useRoute();

    const isDarkPage = computed(() => {
        const page = route.name;
        switch (page) {
            case 'Welcome':
            case 'HowItWorks':
            case 'StartRequest':
            case 'New-Request':
            case 'RequestView':
            case 'ResultView':
            case 'ProfileView':
            case 'Payment':
            case 'ErrorView':
                return true;
            default:
                return false;
        }
    });

    return {
        isDarkPage
    };
}
