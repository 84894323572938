import {createRouter as createVueRouter, createWebHashHistory, createWebHistory, Router} from 'vue-router'
import Welcome from '@/views/Welcome.vue'
import HowItWorks from "@/views/HowItWorks.vue";
import StartRequest from "@/views/StartRequest.vue";
// import QuestionView from "@/views/QuestionView.vue";
import QuestionView from "@/views/QuestionView.vue";
import RequestForm from "@/views/RequestForm.vue";
import Result from "@/views/Result.vue";
import RequestOverview from "@/views/RequestOverview.vue";
import Login from "@/views/old/Login.vue";
import Register from "@/views/old/Register.vue";
import VerifyMail from "@/views/old/VerifyMail.vue";
import ForgotPassword from "@/views/old/ForgotPassword.vue";
import VerifyCode from "@/views/VerifyCode.vue";
import PaymentSucces from "@/views/PaymentSucces.vue";
import RegistrationSucces from "@/views/old/RegistrationSucces.vue";
import VerificationSucces from "@/views/old/VerificationSucces.vue";
import PasswordReset from "@/views/old/PasswordReset.vue";
import PasswordResetSucces from "@/views/old/PasswordResetSucces.vue";
import Logout from "@/views/old/Logout.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import {useUserStore} from "@/store/user";
//
// const router = createRouter({
//   history: createWebHistory(),
//   routes: [
//     {
//       path: "/",
//       name: "Welcome",
//       component: Welcome,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/hoe-werkt-het",
//       name: "HowItWorks",
//       component: HowItWorks,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/start-aanvraag",
//       name: "StartRequest",
//       component: StartRequest,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/vragenlijst",
//       name: "QuestionList",
//       component: () =>
//           import("@/views/QuestionView.vue"),
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/nieuwe-aanvraag",
//       name: "New-Request",
//       component: RequestForm,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/resultaat",
//       name: "ResultView",
//       component: Result,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/overzicht",
//       name: "RequestView",
//       component: RequestOverview,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/login",
//       name: "LoginView",
//       component: Login,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/registreren",
//       name: "RegisterView",
//       component: Register,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/registreren/succes",
//       name: "RegistrationSuccesView",
//       component: RegistrationSucces,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/verificatie/mail",
//       name: "VerifyMailView",
//       component: VerifyMail,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/verificatie/:code",
//       name: "VerifyCodeView",
//       component: VerifyCode,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/verificatie/succes",
//       name: "VerificationSuccesView",
//       component: VerificationSucces,
//       props: true,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/wachtwoord/vergeten",
//       name: "ForgotPasswordView",
//       component: ForgotPassword,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/wachtwoord/reset",
//       name: "PasswordReset",
//       component: PasswordReset,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/wachtwoord/succes",
//       name: "PasswordSuccesView",
//       component: PasswordResetSucces,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/betaling/succes",
//       name: "PaymentSucces",
//       component: PaymentSucces,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/uitloggen",
//       name: "LoggedOutView",
//       component: Logout,
//       meta: {
//         needsAuth: false,
//       },
//     },
//     {
//       path: "/:pathMatch(.*)*",
//       name: 'ErrorView',
//       component: PageNotFound,
//       meta: {
//         needsAuth: false,
//       },
//     }
//   ],
// });

// @ts-ignore
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from 'vue';
import ProfileView from "@/views/ProfileView.vue";
import PaymentExpired from "@/views/PaymentExpired.vue";
import PaymentFailed from "@/views/PaymentFailed.vue";
import Payment from "@/views/Payment.vue";

export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "Welcome",
        component: Welcome,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/hoe-werkt-het",
        name: "HowItWorks",
        component: HowItWorks,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/start-aanvraag",
        name: "StartRequest",
        component: StartRequest,
        // beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/vragenlijst/:id",
        name: "QuestionList",
        component: QuestionView,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: true,
        },
      },
      {
        path: "/nieuwe-aanvraag",
        name: "New-Request",
        component: RequestForm,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/resultaat",
        name: "ResultView",
        component: Result,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/overzicht",
        name: "RequestView",
        component: RequestOverview,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/profile",
        name: "ProfileView",
        component: ProfileView,
        beforeEnter: createAuthGuard(app),
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/betaling/:paymentStatus",
        name: "Payment",
        component: Payment,
        meta: {
          needsAuth: false,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: 'ErrorView',
        component: PageNotFound,
        meta: {
          needsAuth: false,
        }
      }
    ],
    history: createWebHistory(),
  })
}
